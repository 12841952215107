import React from "react";

const EmailIcon = () => (
  <svg viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="M 20.051 20.699 L 3.914 20.699 C 1.808 20.699 0.095 18.986 0.095 16.88 L 0.095 6.693 C 0.095 4.587 1.808 2.874 3.914 2.874 L 20.051 2.874 C 22.157 2.874 23.869 4.587 23.869 6.693 L 23.869 16.88 C 23.869 18.986 22.157 20.699 20.051 20.699 Z M 3.914 3.991 C 2.423 3.991 1.212 5.202 1.212 6.693 L 1.212 16.88 C 1.212 18.369 2.423 19.582 3.914 19.582 L 20.051 19.582 C 21.541 19.582 22.752 18.369 22.752 16.88 L 22.752 6.693 C 22.752 5.202 21.541 3.991 20.051 3.991 L 3.914 3.991 Z"></path>
    <path d="M 3.013 6.745 C 5.866 9.527 8.721 12.307 11.574 15.088 C 12.107 15.607 12.923 14.792 12.39 14.273 C 9.535 11.492 6.682 8.711 3.827 5.93 C 3.296 5.412 2.48 6.227 3.013 6.745 Z"></path>
    <path d="M 12.39 15.088 C 15.244 12.307 18.098 9.527 20.953 6.746 C 21.485 6.227 20.668 5.412 20.136 5.93 C 17.282 8.711 14.428 11.492 11.574 14.273 C 11.042 14.792 11.858 15.607 12.39 15.088 Z"></path>
    <path d="M 20.92 17.056 C 19.021 15.256 17.122 13.455 15.223 11.654 C 14.685 11.144 13.867 11.958 14.407 12.47 C 16.306 14.271 18.206 16.072 20.105 17.873 C 20.644 18.384 21.461 17.569 20.92 17.056 Z"></path>
    <path d="M 3.911 17.873 C 5.81 16.071 7.709 14.271 9.608 12.47 C 10.149 11.958 9.331 11.142 8.793 11.653 C 6.894 13.455 4.994 15.256 3.095 17.056 C 2.556 17.569 3.372 18.384 3.911 17.873 Z"></path>
  </svg>
);

export default EmailIcon;
